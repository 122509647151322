import React from "react";

let Home = () => {
    return (
        <h1 className="text-3xl font-bold">
            Aplikasi Test API dengan React
        </h1>
    )
}

export default Home